<template>
  <div id="app">
    <Header></Header>
    <Swiper
      v-show="
        $route.name != 'ProjectDetails' && $route.name != 'ContentDetails'
      "
    ></Swiper>
    <router-view :key="key" />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Swiper from "@/components/Swiper.vue";
import Footer from "@/components/Footer.vue";
import { queryColumnList } from "@/api/common";
export default {
  components: {
    Header,
    Swiper,
    Footer,
  },
  data() {
    return {
      key: null,
    };
  },
  watch: {
    $route: {
      handler(newValue) {
        if (newValue && newValue.query.activeSunId) {
          this.key = newValue.query.activeSunId;
        } else if (newValue && newValue.query.activeMenuId) {
          this.key = newValue.query.activeMenuId;
        }
      },
    },
  },
  // beforeCreate() {
  //   queryColumnList().then((res) => {
  //     if (res.status != 200) return;
  //     console.log(2.1)
  //     localStorage.setItem("menus", JSON.stringify(res.data));
  //   });
  // },
};
</script>

<style></style>
